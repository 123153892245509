.related {
  margin-bottom: 30px;
  width: 28vh;
  display: flex; /* cria um container flexível */
  align-items: center; /* centraliza o elemento verticalmente */
}

.related--thumbnails {
  cursor: pointer;
  margin-left: 10px;
  width: 170px;
  height: 100px;
  transform: scale(0.9);
  transition: all ease 0 2s;
  object-fit: fill;
  aspect-ratio: 16/9;
}

.related--thumbnails:hover {
  transform: scale(1);
  transition: all ease 0.2s;
}

.related--title {
  cursor: pointer;
  margin-left: 7px !important;
  text-align: center;
}
