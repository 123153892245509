.donate {
  padding-left: 7px;
  color: #bdbdbd;
  font-size: small !important;
}

.pix {
  text-align: -webkit-center;
}

.pix div {
  padding-bottom: 6px;
}

.paypal {
  text-align: -webkit-center;
}

.paypal div {
  padding-bottom: 6px;
}
