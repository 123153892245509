
.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    min-width: 50%;
    border: 2px solid #FFC000;
    background-color: black;
    box-shadow: 24;
    padding: 12px;
    overflow-x: auto;
  }
  
  /* Correção do tamanho da imagem */
  .popup img {
    width: 100%;
  }
  
  /* Exibição do link sublinhado */
  .popup a {
    text-decoration: underline;
  }

  /* Personalização do scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
} 

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 191, 0, 0.5);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FFC000;
  }
