@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Signika:wght@300&display=swap');
* {
  box-sizing: border-box;
}

body {
  background-color: #111;
  color: #FFF;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.lists {
  margin-top: -280px;
}

footer {
  text-align: center;
  color: #999;
  font-size: 14px;
  margin-right: 20px;
  margin-bottom: 20px;
}

footer .language--selector {
  float: left;
  margin-top: -15px;
  margin-left: 30px;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

}

@media (max-width: 760px) {
  .lists {
    margin-top: -140px;
  }
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}