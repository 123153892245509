.header--logo {
  height: 56px;
}

.header--logo img {
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  transition: all ease 0.5s;
  height: 42px;
  padding-top: 7px;
  padding-left: 7px;
  margin-bottom: -4px;
}

.line {
  height: 2px;
  background: black;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(#e0aa3e),
    to(#f9f295),
    color-stop(50%, #fff9d4)
  );
}
