.page--title {
  display: inline-flex;
}

.page--icon-label {
  margin-left: 4px !important;
}

.page {
  padding: 7px;
}

.page--synopsis {
  overflow-x: auto;
  padding-right: 7px;
  height: 340px !important;
}

/* Correção do tamanho da imagem */
.page--synopsis img {
  width: 100%;
}

/* Exibição do link sublinhado */
.page--synopsis a {
  text-decoration: underline;
}

/* Personalização do scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
} 

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 191, 0, 0.5);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FFC000;
  }

/*
  xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px 
*/
@media (min-width: 1200px) {
  .page--synopsis {
    height: 280px;
  }
}

/* Imagem de fundo com efeitos de sombra */
.backdrop {
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.backdrop--vertical-shadow {
  width: inherit;
  height: inherit;
  background: linear-gradient(to top, #111 10%, transparent 90%);
}

.backdrop--horizontal-shadow {
  width: inherit;
  height: inherit;
  background: linear-gradient(to right, #111 20%, transparent);
}
