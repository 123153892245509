.card {
  border-style: dotted;
  margin: 20px;
  padding: 10px;
}

.card h3 {
  border-bottom-style: double;
}

.card a {
  color: orange;
  text-decoration: underline;
}

.error {
  color: red;
}

.ref {
  margin-top: 20px;
  font-size: xx-small;
}
