@import '/node_modules/react-modal-video/scss/modal-video.scss';

.modal-video-movie-wrap {
  position: 'relative';
  padding-bottom: '56.25%'; /* 16:9 */
  padding-top: 25;
  height: 0;
  display: contents;
}

.modal-video-movie-wrap iframe {
  width: 100%;
  height: 90%;
}

.video--backdrop {
  width: 680px;
  height: 382px;
  border-style: solid;
}

.video--backdrop.without-video {
  border-color: #ffd600;
  pointer-events: none;
}

.video--backdrop.with-video {
  border-color: #18ffff;
  cursor: pointer;
}

.video--play-button {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
  opacity: 1;
  transition: all ease 0.2s;
  background-color: #18ffff;
  color: #000;
  border-style: none;
  position: absolute;
  margin-left: -114px;
}
